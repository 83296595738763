/* AddProduct.css */

.add-product-form-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.add-product-form-container h2 {
  text-align: center;
  color: #333;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

.add-product-form-container form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.add-product-form-label {
  font-weight: 600;
  color: #444;
}

.add-product-input,
.add-product-textarea,
.add-product-file-input {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.3s ease;
  background-color: #f7f7f7;
}

.add-product-input:focus,
.add-product-textarea:focus,
.add-product-file-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
  background-color: #fff;
}

.add-product-textarea {
  resize: vertical;
  min-height: 120px;
}

.add-product-submit-btn {
  padding: 0.8rem;
  background-color: #007bff;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
}

.add-product-submit-btn:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0 6px 18px rgba(0, 123, 255, 0.3);
}

/* Placeholder Styling */
.add-product-input::placeholder,
.add-product-textarea::placeholder {
  color: #aaa;
  font-style: italic;
}

/* Responsive Design */
@media (max-width: 600px) {
  .add-product-form-container {
    padding: 1.5rem;
  }
}
