.transaction-history-container {
  width: 100%;
  max-width: 100%;
  margin: auto;
  background-color: #f9fafa;
  border-radius: 8px;
  padding: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  /* Removed overflow-x: auto; as Bootstrap handles it now */
}

.transaction-history-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 10px;
}

.transaction-history-table {
  width: 100%;
  /* Removed min-width: 600px; to allow Bootstrap to manage responsiveness */
  border-collapse: collapse;
}

.transaction-history-header,
.transaction-history-data {
  font-size: 0.9rem;
  color: #343a40;
  padding: 12px 8px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

.transaction-history-row {
  border-bottom: 1px solid #dee2e6;
}

.transaction-history-status {
  display: inline-block;
  padding: 4px 8px;
  background-color: #28a745;
  color: #ffffff;
  border-radius: 4px;
  font-weight: 500;
  font-size: 0.85rem;
}
.bg-success {
  background-color: #28a745 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}
.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-btn {
  border: none;
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
}

.page-btn.active {
  background-color: #007bff;
  color: white;
}

.page-btn:hover {
  background-color: #007bff;
  color: white;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .dashboard h2 {
    font-size: 1.8rem;
  }

  .transaction-history-title {
    font-size: 1.4rem;
  }
}

@media (max-width: 992px) {
  .dashboard h2 {
    font-size: 1.6rem;
  }

  .transaction-history-title {
    font-size: 1.3rem;
  }

  .transaction-history-header,
  .transaction-history-data {
    font-size: 0.85rem;
  }
}

@media (max-width: 768px) {
  .table th,
  .table td {
    display: block;
    text-align: left !important;
  }
  .dashboard h2 {
    font-size: 1.5rem;
  }

  .transaction-history-title {
    font-size: 1.25rem;
  }

  .transaction-history-data,
  .transaction-history-header {
    font-size: 0.8rem;
    padding: 10px 6px;
  }

  .transaction-history-status {
    font-size: 0.75rem;
    padding: 3px 6px;
  }

  .transaction-history-container {
    padding: 15px;
  }
}

@media (max-width: 576px) {
  .dashboard h2 {
    font-size: 1.3rem;
  }

  .transaction-history-title {
    font-size: 1.1rem;
  }

  .transaction-history-data,
  .transaction-history-header {
    font-size: 0.75rem;
    padding: 8px 4px;
  }

  .transaction-history-status {
    font-size: 0.7rem;
    padding: 2px 5px;
  }

  .transaction-history-container {
    padding: 10px;
  }
}
