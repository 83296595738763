/* Navbar */
.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar .badge {
  font-size: 0.7rem;
  padding: 0.3em 0.4em;
}

.navbar .form-control {
  border-radius: 0.25rem;
}

.sidebar-icon {
  font-size: 1.2rem;
}

/* Hide sidebar items on large screens */
@media (min-width: 992px) {
  .offcanvas {
    display: none;
  }
}
@media (max-width: 768px) {
  .navbar .d-lg-flex {
    display: none !important; /* Hides search bar and icons */
  }
}
