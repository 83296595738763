.ezy__team12 {
  --bs-body-color: rgb(50, 62, 80);
  --bs-body-bg: #ffffff;

  --ezy-theme-color: rgb(13, 110, 253);
  --ezy-theme-color-rgb: 13, 110, 253;
  --ezy-card-bg: #00bfa5;
  --ezy-content-color: #ffffff;
  --ezy-box-shadow: 0 7px 65px 0 rgba(0, 0, 0, 0.12);

  background-color: var(--bs-body-bg);
}

/* Gray Block Style */
.gray .ezy__team12,
.ezy__team12.gray {
  --bs-body-bg: rgb(246, 246, 246);
}
.img-fluids {
  object-fit: contain;
  width: 100%;
  height: 300px;
}
.dark-gray .ezy__team12,
.ezy__team12.dark-gray {
  --bs-body-color: #ffffff;
  --bs-body-bg: rgb(30, 39, 53);
  --ezy-content-color: #233346;
  --ezy-card-box-shadow: 0 21px 65px 0 rgba(75, 75, 75, 0.25);
}

/* Dark Block Style */
.dark .ezy__team12,
.ezy__team12.dark {
  --bs-body-color: #ffffff;
  --bs-body-bg: rgb(11, 23, 39);

  --ezy-content-color: #162231;
  --ezy-card-box-shadow: 0 21px 65px 0 rgba(55, 55, 55, 0.25);
}

.ezy__team12-heading {
  font-size: 25px;
  font-weight: 700;
  line-height: 1;
  color: var(--bs-body-color);
  text-align: right;
  margin-right: 15px;
}

@media (min-width: 768px) {
  .ezy__team12-heading {
    font-size: 45px;
  }
}
.ezy__team12-btn {
  background-color: var(--ezy-theme-color);
  border: none;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  text-align: center;
}
.ezy__team12-btn:hover {
  background-color: darken(var(--ezy-theme-color), 10%);
}
.ezy__team12-sub-heading {
  font-size: 17px;
  color: var(--bs-body-color);
  line-height: 1.5;
  opacity: 0.8;
}

.ezy__team12-separator {
  padding: 50px 0;
}

.ezy__team12-each-features {
  margin: 50px 0;
}

@media (min-width: 991px) {
  .ezy__team12-each-features {
    margin: 0;
  }
}

.ezy__team12-features-content {
  position: absolute;
  background-color: var(--ezy-content-color);
  bottom: -50px;
  padding: 20px;
  width: 80%;
  left: 10%;
  box-shadow: var(--ezy-box-shadow);
  z-index: 1;
  overflow: hidden;
}

.ezy__team12-features-content,
.ezy__team12-title,
.ezy__team12-sub-title,
.ezy__team12-social-icon-area {
  transition: all 0.4s ease-out;
}
.delete-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fbf9f9;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.3s;
}
.delete-icon:hover {
  color: #0f0202;
}
.ezy__team12-title {
  color: var(--bs-body-color);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;
  opacity: 0.8;
}

.ezy__team12-sub-title {
  color: var(--bs-body-color);
  font-size: 17px;
  line-height: 1.5;
  opacity: 0.8;
}

.ezy__team12-social-icon-area {
  position: absolute;
  width: 100%;
  left: 0;
  top: auto;
  opacity: 0;
  transform: translateX(0) translateY(30px) translateZ(0) rotate(0deg) scale(1);
}

.ezy__team12-features-inner:hover .ezy__team12-features-content {
  padding: 30px 20px 84px;
  background-color: var(--ezy-theme-color);
}

.ezy__team12-features-inner:hover .ezy__team12-title {
  color: #fff;
}

.ezy__team12-features-inner:hover .ezy__team12-sub-title {
  color: #fff;
  opacity: 0.85;
}

.ezy__team12-features-inner:hover .ezy__team12-social-icon li a {
  color: #fff;
}

.ezy__team12-features-inner:hover .ezy__team12-social-icon-area {
  opacity: 1;
  transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
}

.ezy__team12-social-icon li {
  list-style: none;
  float: left;
  margin-bottom: 0px;
}

.ezy__team12-social-icon li a {
  width: 40px;
  height: 40px;
  font-size: 26px;
  text-align: center;
  margin-top: 8px;
  margin-right: 10px;
  color: var(--ezy-content-color);
  opacity: 0.9;
  padding: 0;
  position: relative;
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.ezy__team12-social-icon li a::before {
  /* content: ""; */
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--ezy-content-color);
  z-index: -1;
  left: 0;
  top: 0;
  transform-origin: 50% 100%;
  transform: rotateX(-90deg) translateY(100%);
  transition: all 0.4s ease-out;
}

.ezy__team12-social-icon li a:hover {
  color: var(--ezy-theme-color) !important;
}

.ezy__team12-social-icon li a:hover::before {
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  transform: rotateX(0deg) translateY(0%);
}

.ezy__team12-social-icon li:last-child a {
  margin-right: 0px;
}
