.sidebar {
  z-index: 1;
  width: 80px;
  transition: width 0.3s ease, opacity 0.3s ease;
  min-height: 100vh;
  /* overflow: hidden; */
  position: relative;
}

.sidebar.open {
  width: 250px;
}

.sidebar .toggle-btn {
  margin-left: auto;
  font-size: 1.2rem;
  color: white;
}

.sidebar .sidebar-icon {
  font-size: 1.2rem;
}

.sidebar .text-white {
  white-space: nowrap;
  overflow: hidden;
  transition: opacity 0.3s ease;
}

.sidebar.closed:hover {
  width: 250px;
}

.sidebar.closed:hover .text-white span {
  opacity: 1;
}

.sidebar.closed .text-white span {
  opacity: 0;
}
/* Responsive styling */
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
  .sidebar.open {
    display: block;
    position: absolute;
    z-index: 1000;
    height: 100vh;
  }

  .app .content {
    padding-left: 0;
  }
}

@media (max-width: 992px) {
  .sidebar {
    display: none;
  }
  .sidebar.closed .text-white span {
    display: none;
  }
}
