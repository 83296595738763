/* Modal overlay for background dimming */
.confirm-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1000;
  pointer-events: none;
}

.confirm-modal-overlay.fade-in {
  opacity: 1;
  pointer-events: auto; /* Enable interaction when modal is visible */
}

.confirm-modal-overlay.fade-out {
  opacity: 0;
  pointer-events: none;
}

/* Modal content for container styling */
.confirm-modal-content {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  transform: translateY(20px);
  transition: transform 0.3s ease, opacity 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

/* Responsive modal content */
@media (max-width: 768px) {
  .confirm-modal-content {
    width: 90%;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .confirm-modal-content {
    width: 95%;
    padding: 10px;
  }
}

/* Animation for modal content */
.confirm-modal-content.slide-up {
  transform: translateY(0);
}

.confirm-modal-content.slide-down {
  transform: translateY(20px);
}

/* Warning message section */
.confirm-modal-body .confirm-modal-warning-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
  font-size: 1rem;
}

.confirm-modal-body .confirm-modal-warning-message h6 {
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

/* Footer section with buttons */
.confirm-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

/* Responsive footer for smaller screens */
@media (max-width: 480px) {
  .confirm-modal-footer {
    flex-direction: column;
  }
}

/* Button styling */
.confirm-modal-btn {
  padding: 10px 16px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 100%;
}

.confirm-modal-cancel-btn {
  background-color: #ddd;
  color: #333;
}

.confirm-modal-cancel-btn:hover {
  background-color: #ccc;
}

.confirm-modal-delete-btn {
  background-color: #ff0000;
  color: white;
}

.confirm-modal-delete-btn:hover {
  background-color: #cc0000;
}
