/* Container styling */
.container {
  max-width: 100%;
  padding: 15px;
}

h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

/* Delete button styling */
.delete-btn {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  border: none;
  background-color: #dc3545;
  transition: background-color 0.3s ease;
}

.delete-btn:hover {
  background-color: #c82333;
}

/* Responsive table styling */
.table-responsive {
  overflow-x: auto;
}

.table th,
.table td {
  padding: 12px;
  text-align: left;
}

.table th {
  background-color: #f8f9fa;
}

@media (max-width: 768px) {
  /* Stack cells for mobile */
  .table th,
  .table td {
    display: block;
    width: 100%;
    text-align: right;
    padding-left: 50%;
  }

  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 15px;
    font-weight: bold;
    text-align: left;
  }

  .table thead {
    display: none;
  }

  .delete-btn {
    font-size: 14px;
    padding: 8px;
  }
}
