.fpw-container {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #f9f9f9;
}

.fpw-container h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  text-align: center;
}

.fpw-container label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.fpw-container input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.fpw-change-btn {
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.fpw-change-btn:hover {
  background-color: #45a049;
}

@media screen and (max-width: 500px) {
  .fpw-container {
    padding: 15px;
  }

  .fpw-container h2 {
    font-size: 1.2em;
  }

  .fpw-change-btn {
    padding: 8px;
    font-size: 0.9em;
  }
}
